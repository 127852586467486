import { styled } from "styled-components";

export const LayoutContent = styled.div`
  overflow: hidden;
  .CERTIFICATE {
    overflow: hidden;
  }

  .effective_call {
    position: fixed;
    bottom: 30px;
    right: 100px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #71d300;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 123;

    svg {
      width: 28px;
      height: 28px;
      animation: callAnim 1.5s infinite;
    }

    &::after {
      content: "";
      width: 60px;
      height: 60px;
      border-radius: 100%;
      background-color: rgba(113, 211, 0.7);
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: ring 3s infinite;
    }

    &::before {
      content: "";
      width: 60px;
      height: 60px;
      border-radius: 100%;
      border: 2px solid #71d300;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: ringBefore 3s infinite;
    }

    @keyframes ring {
      0% {
        width: 60px;
        height: 60px;
        opacity: 1;
      }
      50% {
        width: 90px;
        height: 90px;
        background-color: rgba(113, 211, 0.5);
      }
      100% {
        width: 60px;
        height: 60px;
        opacity: 0;
      }
    }
    @keyframes ringBefore {
      0% {
        width: 80px;
        height: 80px;
        opacity: 1;
      }
      100% {
        width: 120px;
        height: 120px;
        opacity: 0;
      }
    }

    @keyframes callAnim {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(-15deg);
      }
      50% {
        transform: rotate(-25deg);
      }
      75% {
        transform: rotate(-15deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }

    @media screen and (max-width: 540px) {
      right: 30px;
    }
  }
`;

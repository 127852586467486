import React, { Suspense, lazy } from "react";
import { LayoutContent } from "./Layout.style";
import Icons from "assets/Icons";
import Loader from "components/loading/Loader";

const Navbar = lazy(() => import("../pages/navbar/Navbar"));
const Home = lazy(() => import("../pages/home/Home"));
const Info = lazy(() => import("../pages/info/Info"));
const WahtJenshin = lazy(() => import("../pages/whatJenshin/WahtJenshin"));
const Feature = lazy(() => import("../pages/feature/Feature"));
const Content = lazy(() => import("../pages/content/Content"));
const Order = lazy(() => import("../pages/order/Order"));
const Xalal = lazy(() => import("../pages/xalal/Xalal"));
const Advantage = lazy(() => import("../pages/advantage/Advantage"));
const Impact = lazy(() => import("../pages/impact/Impact"));
const Methods = lazy(() => import("../pages/method/Methods"));
const Certificate = lazy(() => import("../pages/certificate/Certificate"));
const Question = lazy(() => import("../pages/questions/Question"));
const Map = lazy(() => import("../pages/map/Map"));
const Footer = lazy(() => import("../pages/footer/Footer"));

const Layout = () => {
  return (
    <Suspense fallback={<Loader />}>
      <LayoutContent>
        <Navbar />
        <Home />
        <Info />
        <WahtJenshin />
        <Feature />
        <Content />
        <Order />
        <Xalal />
        <Advantage />
        <Impact />
        <Methods />
        <Certificate />
        <Question />
        <Map />
        <Footer />

        <a href="tel:+998555127575" className="effective_call">
          <Icons.CallIcon />
        </a>
      </LayoutContent>
    </Suspense>
  );
};

export default Layout;

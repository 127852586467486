const DownIcon = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99953 3.87855L8.71188 0.16626L9.77253 1.22692L4.99953 5.99992L0.226562 1.22692L1.28723 0.16626L4.99953 3.87855Z"
      fill="white"
    />
  </svg>
);

const CallIcon = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6242 9.63695V11.5498C13.6249 11.7274 13.5885 11.9031 13.5174 12.0658C13.4462 12.2285 13.3419 12.3746 13.2111 12.4946C13.0802 12.6147 12.9257 12.7061 12.7575 12.763C12.5893 12.8198 12.4111 12.841 12.2342 12.825C10.2722 12.6118 8.38751 11.9414 6.73164 10.8675C5.19107 9.88859 3.88493 8.58246 2.90599 7.04189C1.82842 5.3785 1.15783 3.48467 0.948535 1.51383C0.932601 1.33751 0.953556 1.1598 1.01006 0.992024C1.06657 0.824246 1.1574 0.670073 1.27675 0.53932C1.39611 0.408567 1.54139 0.304099 1.70333 0.232567C1.86527 0.161036 2.04034 0.124008 2.21737 0.123841H4.1302C4.43963 0.120796 4.73962 0.230372 4.97424 0.432145C5.20886 0.633919 5.36211 0.914122 5.40541 1.22053C5.48615 1.83267 5.63588 2.43372 5.85174 3.01221C5.93753 3.24042 5.95609 3.48844 5.90524 3.72689C5.85439 3.96533 5.73625 4.1842 5.56482 4.35756L4.75505 5.16732C5.66273 6.7636 6.98442 8.0853 8.5807 8.99297L9.39046 8.18321C9.56382 8.01178 9.78269 7.89364 10.0211 7.84278C10.2596 7.79193 10.5076 7.8105 10.7358 7.89628C11.3143 8.11215 11.9154 8.26187 12.5275 8.34261C12.8372 8.3863 13.1201 8.54231 13.3223 8.78096C13.5245 9.01961 13.6319 9.32425 13.6242 9.63695Z"
      fill="white"
    />
  </svg>
);

const ToRight = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_245_48)">
      <path
        d="M1.16797 8L15.168 8"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.168 3.33331L15.8346 7.99998L11.168 12.6666"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_245_48">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.501953)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Tgicon = () => (
  <svg
    width="24"
    height="26"
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C5.37256 0 0 5.6243 0 12.5623C0 19.5002 5.37256 25.1245 12 25.1245C18.6274 25.1245 24 19.5002 24 12.5623C24 5.6243 18.6274 0 12 0ZM17.5622 8.54335C17.3816 10.5298 16.6002 15.3502 16.2025 17.575C16.0343 18.5164 15.7031 18.832 15.3825 18.8629C14.6857 18.9301 14.1564 18.3808 13.4815 17.9176C12.4253 17.1929 11.8287 16.7417 10.8034 16.0345C9.61868 15.2172 10.3867 14.7681 11.0619 14.0336C11.2386 13.8415 14.309 10.9179 14.3685 10.6526C14.3759 10.6195 14.383 10.4956 14.3126 10.4306C14.2423 10.3655 14.1389 10.3876 14.0641 10.4053C13.9582 10.4304 12.271 11.5979 9.00247 13.9076C8.5236 14.2518 8.08982 14.4196 7.70114 14.4108C7.27268 14.4011 6.4485 14.1572 5.83577 13.9487C5.08427 13.693 4.48698 13.5578 4.539 13.1235C4.5661 12.8972 4.86368 12.6659 5.43174 12.4295C8.93 10.834 11.2627 9.78203 12.4299 9.27374C15.7624 7.82264 16.4549 7.57059 16.9062 7.56218C17.0055 7.56045 17.2275 7.58619 17.3712 7.70832C17.4668 7.79531 17.5278 7.91672 17.542 8.04851C17.5664 8.21215 17.5731 8.37813 17.5622 8.54335Z"
      fill="white"
    />
  </svg>
);
const Fsicon = () => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5996 0C5.9722 0 0.599609 5.62557 0.599609 12.5628C0.599609 19.501 5.9722 25.1245 12.5996 25.1245C19.227 25.1245 24.5996 19.501 24.5996 12.5628C24.5996 5.62557 19.2265 0 12.5996 0ZM15.7754 12.5102H13.6975C13.6975 15.9865 13.6975 20.2683 13.6975 20.2683H10.6177C10.6177 20.2683 10.6177 16.0304 10.6177 12.5102H9.15287V9.77116H10.6177V7.9963C10.6177 6.72599 11.1937 4.74299 13.7263 4.74299L16.0069 4.75217V7.41267C16.0069 7.41267 14.62 7.41267 14.351 7.41267C14.0805 7.41267 13.698 7.55399 13.698 8.15802V9.77167H16.0459L15.7754 12.5102Z"
      fill="white"
    />
  </svg>
);
const Insicon = () => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1014 14.9118C13.2868 14.9118 14.2477 13.9058 14.2477 12.6649C14.2477 11.4239 13.2868 10.418 12.1014 10.418C10.916 10.418 9.95508 11.4239 9.95508 12.6649C9.95508 13.9058 10.916 14.9118 12.1014 14.9118Z"
      fill="white"
    />
    <path
      d="M14.7851 7.14941H9.41932C8.63884 7.14941 7.95592 7.40474 7.5169 7.86434C7.07788 8.32393 6.83398 9.03886 6.83398 9.85592V15.4732C6.83398 16.2902 7.07788 17.0052 7.56568 17.5158C8.05348 17.9754 8.68762 18.2308 9.4681 18.2308H14.7851C15.5656 18.2308 16.2485 17.9754 16.6875 17.5158C17.1753 17.0562 17.4192 16.3413 17.4192 15.5243V9.90698C17.4192 9.08993 17.1753 8.42607 16.7363 7.91541C16.2485 7.40474 15.6144 7.14941 14.7851 7.14941ZM12.1022 16.137C10.2486 16.137 8.78518 14.554 8.78518 12.6646C8.78518 10.724 10.2974 9.19206 12.1022 9.19206C13.9071 9.19206 15.468 10.724 15.468 12.6646C15.468 14.6051 13.9558 16.137 12.1022 16.137ZM15.5656 9.85592C15.1266 9.85592 14.7851 9.49845 14.7851 9.03886C14.7851 8.57926 15.1266 8.2218 15.5656 8.2218C16.0046 8.2218 16.346 8.57926 16.346 9.03886C16.346 9.49845 16.0046 9.85592 15.5656 9.85592Z"
      fill="white"
    />
    <path
      d="M12.1991 0C5.565 0 0.199219 5.61727 0.199219 12.5623C0.199219 19.5072 5.565 25.1245 12.1991 25.1245C18.8331 25.1245 24.1989 19.5072 24.1989 12.5623C24.2477 5.61727 18.8331 0 12.1991 0ZM18.5892 15.5241C18.5892 16.6986 18.199 17.7199 17.5161 18.4349C16.8331 19.1498 15.8575 19.5073 14.7844 19.5073H9.46738C8.39423 19.5073 7.41863 19.1498 6.73571 18.4349C6.00402 17.7199 5.66256 16.6986 5.66256 15.5241V9.90682C5.66256 7.55778 7.17473 5.92367 9.46738 5.92367H14.8332C15.9551 5.92367 16.8819 6.3322 17.5648 7.04712C18.2478 7.76205 18.5892 8.7323 18.5892 9.90682V15.5241Z"
      fill="white"
    />
  </svg>
);

const Upicon = () => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289L19.7071 14.2929C20.0976 14.6834 20.0976 15.3166 19.7071 15.7071C19.3166 16.0976 18.6834 16.0976 18.2929 15.7071L12 9.41421L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7Z"
      fill="#fff"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill="#000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
      fill="#000"
    />
  </svg>
);
const Icons = {
  DownIcon,
  CallIcon,
  ToRight,
  Tgicon,
  Upicon,
  Fsicon,
  Insicon,
};
export default Icons;

import React, { Suspense, lazy } from "react";
import { Toaster } from "react-hot-toast";

import Loader from "components/loading/Loader";
import Layout from "modules/layout/Layout";

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Layout />
      <Toaster />
    </Suspense>
  );
}

export default App;
